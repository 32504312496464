import { ALL_ORGS_SELECTED } from 'lib/constants'

import React, { createContext, useContext, useRef, useState, useEffect, ReactNode } from 'react'
import { Organization } from 'lib/types'
import { getAllOrganizations, getOrganizationsV5, getAllOrganizationsNames, getOrganization } from 'models/api'
import { ReportStatus } from 'lib/types/reports'
import { getRequestV5 } from 'models/api/rest'
import { NavItems } from 'lib/types/navItems'
import { setRef } from '@material-ui/core'
import { AlertSessions } from 'models'

// Define the context type
type GlobalContextType = {
  loading: boolean
  orgs: Organization[]
  navItems: NavItems[]
  orgSearchBar: string | undefined
  selectedOrg: Organization | null
  selectedOrgId: string | null
  reportStatus: ReportStatus | null
  refreshFlag: boolean
  setOrgs: React.Dispatch<React.SetStateAction<Organization[]>>
  setNavItems: React.Dispatch<React.SetStateAction<NavItems[]>>
  setOrgSearchBar: React.Dispatch<React.SetStateAction<string | undefined>>
  setSelectedOrg: React.Dispatch<React.SetStateAction<Organization | null>>
  setSelectedOrgId: React.Dispatch<React.SetStateAction<string | null>>
  setReportStatus: React.Dispatch<React.SetStateAction<ReportStatus | null>>
  setRefreshFlag: React.Dispatch<React.SetStateAction<boolean>>
}

// Initialize
const GlobalContext = createContext<GlobalContextType>({
  loading: true,
  orgs: [],
  navItems: [],
  orgSearchBar: undefined,
  selectedOrg: null,
  selectedOrgId: null,
  reportStatus: null,
  refreshFlag: false,
  setOrgs: () => {},
  setNavItems: () => {},
  setOrgSearchBar: () => {},
  setSelectedOrg: () => {},
  setSelectedOrgId: () => {},
  setReportStatus: () => {},
  setRefreshFlag: () => {},
})

type GlobalProviderProps = {
  children: ReactNode
}

export const useGlobal = () => {
  const context = useContext(GlobalContext)
  if (!context) {
    throw new Error('useBeacons must be used within a BeaconsProvider')
  }
  return context
}

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [orgs, setOrgs] = useState<Organization[]>([])
  const [navItems, setNavItems] = useState<NavItems[]>([])
  const [orgSearchBar, setOrgSearchBar] = useState<string | undefined>(undefined)
  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null)
  const [selectedOrgId, setSelectedOrgId] = useState<string | null>(null)
  const [reportStatus, setReportStatus] = useState<ReportStatus | null>(null)
  const [refreshFlag, setRefreshFlag] = useState<boolean>(false)
  const alertSessionsLegacy = AlertSessions.use().alertSessions

  useEffect(() => {
    console.log('Orgs context loaded')
    const lsOrgId = localStorage.getItem('selected_org_id')
    setSelectedOrgId(lsOrgId)
    let orgIsSet = false

    getOrganizationsV5()
      .then(data => {
        setOrgs(data)
        // If it is a single org then set the org and selected org Id
        if (data.length === 1) {
          localStorage.setItem('selected_org_id', data[0].id)
          setSelectedOrg(data[0])
          setSelectedOrgId(data[0].id)
          orgIsSet = true
        }
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
      })

    if (lsOrgId && lsOrgId !== ALL_ORGS_SELECTED && !orgIsSet) {
      getOrganization(lsOrgId)
        .then(data => {
          setSelectedOrg(data)
          setLoading(false)
        })
        .catch(e => {
          setLoading(false)
        })
    }

    getRequestV5({
      endpoint: '/reports/status',
      headers: lsOrgId === null || lsOrgId === ALL_ORGS_SELECTED ? {} : { org_id: lsOrgId },
      params: {},
    }).then(response => {
      setReportStatus(response.data.data)
    })

    getAllOrganizationsNames().then(data => setNavItems(data))
    setRefreshFlag(false)
  }, [refreshFlag])

  const isInitialRender = useRef(true)
  useEffect(() => {
    console.log('Alert Sessions legacy useEffect')
    // This effect is to update the org global card alert count to feel "real time".
    if (!isInitialRender.current) {
      getOrganizationsV5(orgSearchBar)
        .then(data => {
          setOrgs(data)

          const lsOrgId = localStorage.getItem('selected_org_id')
          setSelectedOrgId(lsOrgId)

          if (lsOrgId && lsOrgId !== ALL_ORGS_SELECTED) {
            getOrganization(lsOrgId).then(data => {
              setSelectedOrg(data)
            })
          }

          setLoading(false)
        })
        .catch(e => {
          setLoading(false)
        })
    }
    isInitialRender.current = false
  }, [alertSessionsLegacy, orgSearchBar])

  return (
    <GlobalContext.Provider
      value={{
        loading,
        orgs,
        setOrgs,
        navItems,
        setNavItems,
        orgSearchBar,
        setOrgSearchBar,
        selectedOrg,
        setSelectedOrg,
        selectedOrgId,
        setSelectedOrgId,
        reportStatus,
        setReportStatus,
        refreshFlag,
        setRefreshFlag,
        /* functions */
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
