export const timezones = {
  "Abu Dhabi, Muscat": 14400.0,
  "Adelaide": 34200.0,
  "Alaska Standard Time": -32400.0,
  "Alaska Daylight Time": -28800.0,
  "Almaty, Dhaka": 21600.0,
  "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna Standard Time": 3600.0,
  "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna Daylight Time": 7200.0,
  "Anadyr, Petropavlovsk-Kamchatsky": 43200.0,
  "Athens, Bucharest, Istanbul Standard Time": 7200.0,
  "Athens, Bucharest, Istanbul Daylight Time": 10800.0,
  "Atlantic Standard Time (Canada)": -14400.0,
  "Atlantic Daylight Time (Canada)": -10800.0,
  "Auckland, Wellington, Fiji, Kamchatka Standard Time": 43200.0,
  "Auckland, Wellington, Fiji, Kamchatka Daylight Time": 46800.0,
  "Azores Standard Time": -3600.0,
  "Azores Daylight Time": 0.0,
  "Baghdad": 10800.0,
  "Bangkok, Hanoi, Jakarta": 25200.0,
  "Beijing, Chongqing, Hong Kong, Urumqi": 28800.0,
  "Brisbane": 36000.0,
  "Brussels, Copenhagen, Madrid, Paris Standard Time": 3600.0,
  "Brussels, Copenhagen, Madrid, Paris Daylight Time": 7200.0,
  "Bucharest Standard Time": 7200.0,
  "Bucharest Daylight Time": 10800.0,
  "Buenos Aires, Georgetown": -10800.0,
  "Cairo Standard Time": 7200.0,
  "Cairo Daylight Time": 10800.0,
  "Canberra, Melbourne, Sydney Standard Time": 36000.0,
  "Canberra, Melbourne, Sydney Daylight Time": 39600.0,
  "Cape Verde Islands": -3600.0,
  "Caracas": -16200.0,
  "Casablanca, Monrovia, Reykjavik Standard Time": 0.0,
  "Casablanca, Monrovia, Reykjavik Daylight Time": 3600.0,
  "Central America": -21600.0,
  "Central Standard Time (US & Canada)": -21600.0,
  "Central Daylight Time (US & Canada)": -18000.0,
  "Chennai, Kolkata, Mumbai, New Delhi": 19800.0,
  "Chihuahua, La Paz, Mazatlan Standard Time": -25200.0,
  "Chihuahua, La Paz, Mazatlan Daylight Time": -21600.0,
  "Darwin": 34200.0,
  "Eastern Standard Time (US & Canada)": -18000.0,
  "Eastern Daylight Time (US & Canada)": -14400.0,
  "Edinburgh, London Standard Time": 0.0,
  "Edinburgh, London Daylight Time": 3600.0,
  "Guadalajara, Mexico City, Monterrey Standard Time": -21600.0,
  "Guadalajara, Mexico City, Monterrey Daylight Time": -18000.0,
  "Greenland Standard Time": -10800.0,
  "Greenland Daylight Time": -7200.0,
  "Guam, Port Moresby": 36000.0,
  "Hawaii": -36000.0,
  "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius Standard Time": 7200.0,
  "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius Daylight Time": 10800.0,
  "Islamabad, Karachi, Tashkent": 18000.0,
  "International Date Line West": -43200.0,
  "Irkutsk, Ulaanbaatar": 28800.0,
  "Jerusalem Standard Time": 7200.0,
  "Jerusalem Daylight Time": 10800.0,
  "Kabul": 16200.0,
  "Kaliningrad": 7200.0,
  "Kathmandu": 20700.0,
  "Krasnoyarsk": 25200.0,
  "Kuala Lumpur, Singapore": 28800.0,
  "Kuwait, Riyadh": 10800.0,
  "Magadan, Solomon Islands, New Caledonia": 39600.0,
  "Mid-Atlantic Standard Time": -7200.0,
  "Mid-Atlantic Daylight Time": -3600.0,
  "Midway Island, Samoa": -39600.0,
  "Minsk Standard Time": 10800.0,
  "Minsk Daylight Time": 14400.0,
  "Moscow, St. Petersburg, Volgograd Standard Time": 10800.0,
  "Moscow, St. Petersburg, Volgograd Daylight Time": 14400.0,
  "Mountain Standard Time (US & Canada)": -25200.0,
  "Mountain Daylight Time (US & Canada)": -21600.0,
  "Nairobi": 10800.0,
  "Nuku'alofa": 46800.0,
  "Osaka, Sapporo, Tokyo": 32400.0,
  "Pacific Standard Time (US & Canada)": -28800.0,
  "Pacific Daylight Time (US & Canada)": -25200.0,
  "Perth": 28800.0,
  "Port Louis": 14400.0,
  "Quito": -18000.0,
  "Rangoon": 23400.0,
  "Sarajevo, Skopje, Warsaw, Zagreb Standard Time": 3600.0,
  "Sarajevo, Skopje, Warsaw, Zagreb Daylight Time": 7200.0,
  "Santiago Standard Time": -14400.0,
  "Santiago Daylight Time": -10800.0,
  "Seoul": 32400.0,
  "Sri Jayawardenepura": 19800.0,
  "Taipei": 28800.0,
  "Tehran": 12600.0,
  "Tijuana, Baja California Standard Time": -28800.0,
  "Tijuana, Baja California Daylight Time": -25200.0,
  "UTC": 0.0,
  "Ulaanbaatar": 28800.0,
  "Vladivostok": 36000.0,
  "West Central Africa": 3600.0,
  "Yakutsk": 32400.0,
  "Yerevan": 14400.0,
  "China Daylight Time (UTC+8:30)": 30600.0,
  "China Rakshasa Time (UTC+7:45)": 27900.0
}

// export const timezonesInvert = {
//   14400.0: "Abu Dhabi, Muscat",
//   34200.0: "Adelaide",
//   -32400.0: "Alaska Standard Time",
//   -28800.0: "Alaska Daylight Time",
//   21600.0: "Almaty, Dhaka",
//   3600.0: "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna Standard Time",
//   7200.0: "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna Daylight Time",
//   43200.0: "Anadyr, Petropavlovsk-Kamchatsky",
//   10800.0: "Athens, Bucharest, Istanbul Daylight Time",
//   -14400.0: "Atlantic Standard Time (Canada)",
//   -10800.0: "Atlantic Daylight Time (Canada)",
//   46800.0: "Auckland, Wellington, Fiji, Kamchatka Daylight Time",
//   -3600.0: "Azores Standard Time",
//   0.0: "Azores Daylight Time",
//   10800.0: "Baghdad",
//   25200.0: "Bangkok, Hanoi, Jakarta",
//   28800.0: "Beijing, Chongqing, Hong Kong, Urumqi",
//   36000.0: "Brisbane",
//   3600.0: "Brussels, Copenhagen, Madrid, Paris Standard Time",
//   7200.0: "Brussels, Copenhagen, Madrid, Paris Daylight Time",
//   7200.0: "Bucharest Standard Time",
//   10800.0: "Bucharest Daylight Time",
//   -10800.0: "Buenos Aires, Georgetown",
//   7200.0: "Cairo Standard Time",
//   10800.0: "Cairo Daylight Time",
//   36000.0: "Canberra, Melbourne, Sydney Standard Time",
//   39600.0: "Canberra, Melbourne, Sydney Daylight Time",
//   -3600.0: "Cape Verde Islands",
//   -16200.0: "Caracas",
//   0.0: "Casablanca, Monrovia, Reykjavik Standard Time",
//   3600.0: "Casablanca, Monrovia, Reykjavik Daylight Time",
//   -21600.0: "Central America",
//   -21600.0: "Central Standard Time (US & Canada)",
//   -18000.0: "Central Daylight Time (US & Canada)",
//   19800.0: "Chennai, Kolkata, Mumbai, New Delhi",
//   -25200.0: "Chihuahua, La Paz, Mazatlan Standard Time",
//   -21600.0: "Chihuahua, La Paz, Mazatlan Daylight Time",
//   34200.0: "Darwin",
//   -18000.0: "Eastern Standard Time (US & Canada)",
//   -14400.0: "Eastern Daylight Time (US & Canada)",
//   0.0: "Edinburgh, London Standard Time",
//   3600.0: "Edinburgh, London Daylight Time",
//   -21600.0: "Guadalajara, Mexico City, Monterrey Standard Time",
//   -18000.0: "Guadalajara, Mexico City, Monterrey Daylight Time",
//   -10800.0: "Greenland Standard Time",
//   -7200.0: "Greenland Daylight Time",
//   36000.0: "Guam, Port Moresby",
//   -36000.0: "Hawaii",
//   7200.0: "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius Standard Time",
//   10800.0: "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius Daylight Time",
//   18000.0: "Islamabad, Karachi, Tashkent",
//   -43200.0: "International Date Line West",
//   28800.0: "Irkutsk, Ulaanbaatar",
//   7200.0: "Jerusalem Standard Time",
//   10800.0: "Jerusalem Daylight Time",
//   16200.0: "Kabul",
//   7200.0: "Kaliningrad",
//   20700.0: "Kathmandu",
//   25200.0: "Krasnoyarsk",
//   28800.0: "Kuala Lumpur, Singapore",
//   10800.0: "Kuwait, Riyadh",
//   39600.0: "Magadan, Solomon Islands, New Caledonia",
//   -7200.0: "Mid-Atlantic Standard Time",
//   -3600.0: "Mid-Atlantic Daylight Time",
//   -39600.0: "Midway Island, Samoa",
//   10800.0: "Minsk Standard Time",
//   14400.0: "Minsk Daylight Time",
//   10800.0: "Moscow, St. Petersburg, Volgograd Standard Time",
//   14400.0: "Moscow, St. Petersburg, Volgograd Daylight Time",
//   -25200.0: "Mountain Standard Time (US & Canada)",
//   -21600.0: "Mountain Daylight Time (US & Canada)",
//   10800.0: "Nairobi",
//   46800.0: "Nuku'alofa",
//   32400.0: "Osaka, Sapporo, Tokyo",
//   -28800.0: "Pacific Standard Time (US & Canada)",
//   -25200.0: "Pacific Daylight Time (US & Canada)",
//   28800.0: "Perth",
//   14400.0: "Port Louis",
//   -18000.0: "Quito",
//   23400.0: "Rangoon",
//   3600.0: "Sarajevo, Skopje, Warsaw, Zagreb Standard Time",
//   7200.0: "Sarajevo, Skopje, Warsaw, Zagreb Daylight Time",
//   -14400.0: "Santiago Standard Time",
//   -10800.0: "Santiago Daylight Time",
//   32400.0: "Seoul",
//   19800.0: "Sri Jayawardenepura",
//   28800.0: "Taipei",
//   12600.0: "Tehran",
//   -28800.0: "Tijuana, Baja California Standard Time",
//   -25200.0: "Tijuana, Baja California Daylight Time",
//   0.0: "UTC",
//   28800.0: "Ulaanbaatar",
//   36000.0: "Vladivostok",
//   3600.0: "West Central Africa",
//   32400.0: "Yakutsk",
//   14400.0: "Yerevan",
//   30600.0: "China Daylight Time (UTC+8:30)",
//   27900.0: "China Rakshasa Time (UTC+7:45)"
// }
