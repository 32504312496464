import { FirmwareVersionProps, TimeStamps, UpdateFirmwareProps } from '.'

interface BeaconProps {
  estimatedBatteryLifeTime?: number
  hardwareRevision?: string
  majorId?: string
  minorId?: string
  proximityUuid?: proximityUuid
  version?: FirmwareVersionProps
}

interface proximityUuid {
  name: string
  value: string
}

export interface Beacon extends TimeStamps {
  id: string
  batteryPercent: number
  beaconType: string
  desired_firmware_version?: FirmwareVersionProps
  disabled: boolean
  floor: string
  health?: number
  healthWarnings?: string[]
  name: string
  orgId: string
  props: BeaconProps
  registration: string
  room: string
  updateFirmware?: UpdateFirmwareProps
  appVersion?: string
  lastSeenAt: number
}

export enum BeaconType {
  CARFIT = 'Carfit',
  REACT = 'React',
  REACT2 = 'React2',
  ESTIMOTE = 'Estimote',
  IBEACON = 'IBeacon',
}
