import React, { useState } from 'react'
import { Grid, FormControl, Select, InputLabel, MenuItem } from '@material-ui/core'
import InputMask from 'react-input-mask'
import { timezones } from 'lib/constants/timezones'
import { StyledComponents } from 'components/StyledComponents'
import { StyledFormControl, StyledInputLabel, StyledSelect, StyledSubHeader } from 'components/widgets'
import { Organization, trans, TranslationGroup, TranslationKey } from 'lib/types'
import { flattenOrg, FlattenedOrg, unflattenOrg } from 'models/Orgs'
import { PopUpNotifications } from 'models'
import { Orgs } from 'models'
import { validateOrgData } from 'lib/utils'
import {
  ActionsWrapper,
  ConfirmButton,
  StyledButton,
  SectionWrapper,
} from 'components/partial-pages/GeneralSettingsPage/Common'

const { find } = require('art-comprehensions')

export const OrganizationDetailsForm = ({
  organization,
  editAsGroup,
}: {
  organization: Organization
  editAsGroup?: boolean
}) => {
  const translation: TranslationGroup = trans.merge(TranslationKey.ADMIN)
  const [savedOrg, setSavedOrg] = useState(() => flattenOrg(organization))
  const [unsavedOrg, setUnsavedOrg] = useState(() => flattenOrg(organization))
  const [isChanged, setIsChanged] = useState<boolean>(false)
  const propertyType = editAsGroup ? translation.group : translation.property

  function applyEdit(field: string, value: string) {
    if (value.length >= 255) return
    // @ts-ignore
    const newUnsavedOrg: FlattenedOrg = { ...unsavedOrg, [field]: value }
    setUnsavedOrg(newUnsavedOrg)

    const errorMessage = validateOrgData(newUnsavedOrg)
    PopUpNotifications.reset()

    if (errorMessage) {
      PopUpNotifications.fireError({ content: errorMessage })
    } else {
      const hasChanges = !!find(
        newUnsavedOrg,
        // @ts-ignore
        (value, key) => {
          return value !== savedOrg[key]
        },
      )
      setIsChanged(hasChanges)
    }
  }

  function handleFormCancel() {
    setUnsavedOrg(savedOrg)
    setIsChanged(false)
  }

  function handleFormSubmit() {
    Orgs.createOrUpdate(unflattenOrg(unsavedOrg)).then(() => {
      PopUpNotifications.fireSuccess({ content: `${propertyType} ${translation.updated}` })
      setUnsavedOrg(unsavedOrg)
      setSavedOrg(unsavedOrg)
      setIsChanged(false)
    })
  }

  function getCommonProps(field: string) {
    return {
      id: `org-${field}`,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        return applyEdit(field, (e.target as HTMLInputElement).value)
      },
      value: unsavedOrg[field] || '',
      inputProps: { maxLength: 255 },
    }
  }

  const formFields = editAsGroup
    ? [[['Name', 'name']]]
    : [
        [
          [translation.name, 'name'],
          [translation.phone, 'phone', '(999) 999-9999'],
        ],
        [
          [translation.proximity_uuid, 'proximityUuidValue', '********-****-****-****-************'],
          [translation.proximity_uuid_friendly_name, 'proximityUuidName'],
        ],
        [
          [translation.address_line_1, 'street1'],
          [translation.address_line_2, 'street2'],
        ],
        [
          [translation.city, 'city'],
          [translation.state, 'state'],
        ],
        [
          [translation.zipcode, 'zipcode'],
          [translation.country, 'country'],
        ],
      ]

  const defaultTzOffset = organization.tzOffset ?? '' // Default to "" if tzOffset is not set

  return (
    <SectionWrapper>
      <StyledSubHeader>{`${propertyType} ${translation.details}`}</StyledSubHeader>
      <form>
        {formFields.map(line => (
          <Grid key={`line-${line[0][0]}`} container spacing={2}>
            {line.map(([label, field, mask]) => (
              <Grid key={`item-${field}`} item xs={6}>
                <StyledFormControl>
                  <StyledInputLabel htmlFor={`org-${field}`}>{label}</StyledInputLabel>
                  {mask ? (
                    <InputMask mask={mask} maskPlaceholder={null} {...getCommonProps(field)}>
                      {() => <StyledComponents.StyledInput />}
                    </InputMask>
                  ) : (
                    <StyledComponents.StyledInput {...getCommonProps(field)} />
                  )}
                </StyledFormControl>
              </Grid>
            ))}
          </Grid>
        ))}
        <StyledFormControl style={{ width: '49%' }}>
          <StyledInputLabel id="timezone-select-label">Timezone</StyledInputLabel>
          <StyledSelect
            labelId="timezone-select-label"
            id="timezone-select"
            value={organization?.tzOffset || defaultTzOffset}
            onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
              console.log(e.target.value)
              applyEdit('tzOffset', e.target.value as string)
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {Object.entries(timezones).map(([city, offset]) => (
              <MenuItem key={city} value={offset.toString()}>
                {city} (UTC{offset >= 0 ? `+${offset / 3600}` : offset / 3600})
              </MenuItem>
            ))}
          </StyledSelect>
        </StyledFormControl>
        <ActionsWrapper>
          <StyledButton onClick={handleFormCancel}>{translation.cancel}</StyledButton>
          <ConfirmButton disabled={!isChanged} onClick={handleFormSubmit}>
            {translation.apply_changes}
          </ConfirmButton>
        </ActionsWrapper>
      </form>
    </SectionWrapper>
  )
}
