import { memo, useEffect, useState } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import AlertsView from 'components/partial-pages/AlertsView/AlertsView'
import BeaconsPage from 'components/partial-pages/BeaconsPage/BeaconsPage'
import { DevicesPage } from 'components/partial-pages/ButtonsDevicesPage'
import MainDashboardPage from 'components/partials/_legacy/MainDashboardPage/MainDashboardPage'
import UsersPage from 'components/partial-pages/UsersPage/UsersPage'
import GeneralSettingsPage from 'components/partial-pages/GeneralSettingsPage/GeneralSettingsPage'
import { AlertSourcesView } from 'components/partial-pages/ButtonsDevicesPage/AlertSourcesView'
import { RespondersPage } from 'components/partial-pages/RespondersPage/RespondersPage'
import { MainHeader, StaticModal } from 'components/partials'
import { MainSidebar, DetailSlider } from 'components/partials'
import {
  DASHBOARD_MAIN_PATH,
  GENERAL_SETTINGS_PATH,
  PATH_ADMIN_ALL,
  PATH_ALERTS,
  PATH_DEVICES,
  PATH_RESPONDERS,
  PATH_BEACONS,
  PATH_USERS,
  ALL_ORGS_SELECTED,
} from 'lib/constants'
import UseDesktopNotifications from '../legacy-poorly-defined/useDesktopNotifications'
import { reloadAll, Orgs, NavState } from 'models'
import { PortalRequired } from 'components/partials/PortalRequired'
import { AdminView } from 'components/partial-pages/AdminView'
import { scrollToTop } from 'lib/utils'
import { useIdleTimer } from 'react-idle-timer'
import { RenderCounter } from 'components/widgets/RenderCounter'
import { reload as respondersReload } from 'models/Responders'
import { reload as beaconsLoad } from 'models/Beacons'
import { reload as alertSessionsReload } from 'models/AlertSessions'
import { reload as devicesReload } from 'models/Devices'
import { reload as orgsReload } from 'models/Orgs'
import { PageWrapper, ViewWrapper } from 'components/widgets'
import AudioContextOverlay from 'components/widgets/AudioContextOverlay'

import { BeaconsProvider } from 'contexts/BeaconsProvider'
import { DevicesProvider } from 'contexts/DevicesProvider'
import { useGlobal } from 'contexts/GlobalProvider'
import { AlertsProvider } from 'contexts/AlertsProvider'
import { RespondersProvider } from 'contexts/RespondersProvider'
import { AdminProvider } from 'contexts/AdminProvider'

const MainPage = () => {
  if ('Notification' in window) {
    // your code using Notification API here
    // Desktop permission request
    Notification.requestPermission().then(result => {
      console.log('Notification Permission:', result)
    })
  } else {
    console.log('This browser does not support notifications.')
  }

  const { pathname } = useLocation()
  const isAdminPage = pathname.split('/')[2] === 'admin' ? true : false
  const isMainPage = pathname.split('/')[2] === 'main' ? true : false

  const { orgs, selectedOrgId, selectedOrg } = useGlobal()
  const [section, setSection] = useState('')

  useIdleTimer({
    timeout: 1000 * 60 * 60, // Refresh data 1x per hour when idle
    crossTab: true,
    syncTimers: 200,
    onIdle: () => {
      reloadAll()
    },
  })

  const getSection = () => {
    const parts = pathname.split('/')
    return parts.length > 1 ? parts[2] : parts[parts.length - 1]
  }

  useEffect(() => {
    reloadAll()
  }, [])

  useEffect(() => {
    const newSection = getSection()
    if (newSection !== section) {
      setSection(newSection)
      scrollToTop()
    }
  }, [pathname])

  return (
    <PortalRequired>
      <>
        <RenderCounter name={'MainPage'} />
        <MainHeader />
        <AudioContextOverlay />
        <UseDesktopNotifications />
        <MainSidebar />

        <>
          <Switch key={selectedOrgId}>
            <Route exact path={DASHBOARD_MAIN_PATH}>
              <MainDashboardPage />
            </Route>
            <Route exact path={PATH_BEACONS}>
              <BeaconsProvider>
                <BeaconsPage />
              </BeaconsProvider>
            </Route>
            <Route exact path={PATH_DEVICES}>
              <DevicesProvider>
                <DevicesPage />
              </DevicesProvider>
            </Route>
            <Route exact path={PATH_RESPONDERS}>
              <RespondersProvider>
                <RespondersPage />
              </RespondersProvider>
            </Route>
            <Route exact path={PATH_USERS}>
              <UsersPage />
            </Route>
            <Route exact path={PATH_ALERTS}>
              <AlertsProvider>
                <AlertsView />
              </AlertsProvider>
            </Route>
            <Route exact path={PATH_ADMIN_ALL}>
              <AdminProvider>
                <AdminView />
              </AdminProvider>
            </Route>
            <Route exact path={GENERAL_SETTINGS_PATH}>
              {selectedOrg ? (
                <GeneralSettingsPage />
              ) : (
                <PageWrapper>
                  <ViewWrapper>
                    <p>Please select an organization</p>
                  </ViewWrapper>
                </PageWrapper>
              )}
            </Route>
          </Switch>
        </>

        <DetailSlider />
        <StaticModal />
      </>
    </PortalRequired>
  )
}

export default memo(MainPage)
